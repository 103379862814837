import {
  formatJsonImgUrl,
  formatOldJson,
  updateDefaultValues,
  updateScenesAndHotspots,
  updateMenuItemsWithScene,
  updateItemId,
  updateFeatureMedia,
  checkEnableBranding,
} from './jsonHelper';
import configs from '../configs';
import { combineTour } from './tourHelper';

export const loadJson = async (url) => {
  try {
    const response = await fetch(url + '?v=' + new Date().getTime());
    return await response.json();
  } catch (error) {
    window.logMessage('load.json.error:', error);
    return null;
  }
};

export const loadVGPJson = async (url) => {
  try {
    const response = await fetch(url);
    return await response.json();
  } catch (error) {
    window.logMessage('load.json.error:', error);
    return null;
  }
};

export const ensureData = (data) => {
  updateFeatureMedia(data);
  updateScenesAndHotspots(data);
  updateMenuItemsWithScene(data);
  updateItemId(data);
  updateDefaultValues(data);
  return data;
};

export const loadFromUrl = async (jsonUrl) => {
  const json = await loadVGPJson(jsonUrl);
  if (json) {
    const data = formatOldJson(formatJsonImgUrl(jsonUrl, json));
    return ensureData(data);
  }
  return null;
};

export const loadTourSubscription = async (jsonUrl) => {
  const response = await fetch(jsonUrl);
  if (response) return response.json();
  return null;
};

export const loadTourDetail = async (tourId) => {
  const response = await fetch(`${configs.apiUrl}Tours/${tourId}/detail`);
  const data = await response.json();
  return data.data;
};

export const loadTourJsons = async (tourId) => {
  try {
    const reqBody = {
      tours: [tourId],
    };
    if (configs.viewMode) {
      reqBody.isPublished = true;
    }
    const response = await fetch(`${configs.apiUrl}Tours/json`, {
      method: 'PATCH',
      body: JSON.stringify(reqBody),
    });
    const { data } = await response.json();
    if (data && data.length) {
      return data;
    }
  } catch (error) {
    window.logMessage('loadTourJsons.error', error);
  }
  return [];
};

export const loadPreviewJson = async (tourId) => {
  const data = await loadTourJsons(tourId);
  if (data && data.length) {
    return data.pop();
  }
  return null;
};

export const loadPublishedJson = async (tourId) => {
  const data = await loadTourJsons(tourId);
  if (data && data.length) {
    return data.find((d) => d.isPublished) || null;
  }
  return null;
};

var parsePreloadJsonScript = (jsonStr) => {
  const jsonStrFormatted = jsonStr
    .replace(/__OPEN_TAG__/g, '<')
    .replace(/__CLOSE_TAG__/g, '>');
  return JSON.parse(jsonStrFormatted);
};

export const loadTourJson = async (tourAccessCode) => {
  const data = {
    tour: null,
    json: null,
  };

  const preloadEl = document.getElementById('vizion-data');

  if (preloadEl) {
    data.json = ensureData(parsePreloadJsonScript(preloadEl.textContent));
    data.tour = combineTour(data.json.tour, data.json);
    data.tour = checkEnableBranding(data.tour);
    window.data = data;
    window.logMessage('window.data', data);

    return Promise.resolve(data);
  }

  // const tour = await loadTourDetail(tourAccessCode);
  // if (!tour?.id) return data;
  // if (!tour.active) return { tour };
  // const requestFunction = configs.viewMode
  //   ? loadPublishedJson
  //   : loadPreviewJson;
  // const jsonRecord = await requestFunction(tour.id);
  // window.logMessage('jsonRecord', jsonRecord);

  // if (jsonRecord?.jsonUrl) {

  try {
    const json = await loadFromUrl('/assets/mobile-home-json/mobile-home.json');

    const tour = {};
    tour.subscriptionPlan = await loadTourSubscription(
      '/assets/mobile-home-json/tour-subscription.json'
    );

    if (json) {
      json.tour.active = true;
      data.tour = combineTour(json.tour, json);
      data.tour = checkEnableBranding(data.tour, tour.subscriptionPlan);
      data.tour.subscriptionPlan = tour.subscriptionPlan;
      data.json = json;
    }
  } catch (error) {
    window.logMessage('loadTourJson.error', error);
  }
  window.data = data;
  window.logMessage('window.data', data);
  return data;
};
