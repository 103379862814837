import { ReactComponent as CompressIcon } from 'assets/icons/fa-compress.svg';
import { ReactComponent as ExpandIcon } from 'assets/icons/fa-expand.svg';
import { ReactComponent as MapLocationDotIcon } from 'assets/icons/fa-map-location-dot.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/fa-question.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/fa-share-nodes.svg';
import { ReactComponent as VolumeOnIcon } from 'assets/icons/fa-volume-high.svg';
import { ReactComponent as VolumeMuteIcon } from 'assets/icons/fa-volume-xmark.svg';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAudio } from 'store/actions';
import { canFullscreen, toggleFullScreen } from 'utils';
import MapFloorPlan from './MapFloorPlan';
import './mobiController.scss';
import SharingLinks from './SharingLinks';

const MobiController = ({
  currentPano,
  isShowMapFloorPlan,
  floorMaps,
  selectSceneById,
  showLanding,
}) => {
  const [toggleMapFloorPlan, setToggleMapFloorPlan] = useState(false);
  const [toggleSharing, setToggleSharing] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);

  const audio = useSelector((state) => state.audio);

  const dispatch = useDispatch();

  const handleToggleAudio = () => {
    dispatch(toggleAudio(!audio.enabled));
  };

  const handleToggleFloorPlan = useCallback(() => {
    setToggleMapFloorPlan((value) => !value);
  }, []);

  const handleFullScreen = () => {
    toggleFullScreen();
    setFullScreen(!fullScreen);
  };

  const handleToggleHelp = () => {
    showLanding();
  };

  const handleToggleSharing = () => {
    setToggleSharing((value) => !value);
  };

  const isDiamondRoom = useMemo(
    () =>
      currentPano?._id === '66977e2d4bd0d819589690c2' ||
      currentPano?._id === '66977e114bd0d819589690a4' ||
      currentPano?._id === '66977e374bd0d819589690cc' ||
      currentPano?._id === '66977e014bd0d8195896908f',
    [currentPano?._id]
  );

  const isOvalRoom = useMemo(
    () =>
      currentPano?._id === '66977d6e4bd0d81958968ff7' ||
      currentPano?._id === '66977df74bd0d8195896907f' ||
      currentPano?._id === '66977e534bd0d819589690eb' ||
      currentPano?._id === '66977d7aa82dfc1b80de1722' ||
      currentPano?._id === '66977def4bd0d81958969075' ||
      currentPano?._id === '66977e534bd0d819589690eb' ||
      currentPano?._id === '66977e4a4bd0d819589690e1',
    [currentPano?._id]
  );

  return (
    <>
      <div className="mobi-controller-container">
        <div className="controller-list">
          {isShowMapFloorPlan && (
            <div className="controller-list-item">
              {!toggleMapFloorPlan && (
                <div className="controller-list-item-popover">
                  <div className="popover-content">Sơ đồ khu vực</div>
                </div>
              )}
              <MapLocationDotIcon
                className="icon"
                onClick={handleToggleFloorPlan}
              />
              <MapFloorPlan
                toggleMapFloorPlan={toggleMapFloorPlan}
                handleToggleFloorPlan={handleToggleFloorPlan}
                currentPano={currentPano}
                floorMaps={floorMaps}
                selectSceneById={selectSceneById}
              />
            </div>
          )}

          {audio?.available && (
            <div className={`controller-list-item`} onClick={handleToggleAudio}>
              <div className="controller-list-item-popover">
                <div className="popover-content">Bật tắt âm thanh</div>
              </div>
              {audio?.enabled ? (
                <VolumeOnIcon className="icon" />
              ) : (
                <VolumeMuteIcon className="icon" />
              )}
            </div>
          )}

          {canFullscreen() && (
            <div className={`controller-list-item`} onClick={handleFullScreen}>
              <div className="controller-list-item-popover">
                <div className="popover-content">Bật tắt xem toàn màn hình</div>
              </div>
              {!fullScreen ? (
                <ExpandIcon className="icon" />
              ) : (
                <CompressIcon className="icon" />
              )}
            </div>
          )}

          <div className={`controller-list-item`}>
            {!toggleSharing && (
              <div className="controller-list-item-popover">
                <div className="popover-content">Chia sẻ</div>
              </div>
            )}
            <ShareIcon className="icon" onClick={handleToggleSharing} />
            <SharingLinks
              toggleSharing={toggleSharing}
              handleToggleSharing={handleToggleSharing}
            />
          </div>

          <div className={`controller-list-item`} onClick={handleToggleHelp}>
            <div className="controller-list-item-popover">
              <div className="popover-content">Hướng dẫn</div>
            </div>
            <HelpIcon className="icon" />
          </div>
        </div>
      </div>

      {isOvalRoom && (
        <div className="mobi-controller--menu">
          <h3 className="mobi-controller--menu-title">Các mẫu nhà Semi Oval</h3>
          <div className="mobi-controller--menu-list">
            <div
              className={`mobi-controller--menu-item ${
                currentPano.id === 'mau-nha-semi-oval-twin' ||
                currentPano.id === 'mau-nha-semi-oval-twin-2'
                  ? 'active'
                  : ''
              }`}
              onClick={() =>
                selectSceneById('welcome', 'mau-nha-semi-oval-twin')
              }
            >
              <p>Twin</p>
            </div>
            <div
              className={`mobi-controller--menu-item ${
                currentPano.id === 'semi-oval-king' ? 'active' : ''
              }`}
              onClick={() => selectSceneById('welcome', 'semi-oval-king')}
            >
              <p>King</p>
            </div>
            <div
              className={`mobi-controller--menu-item ${
                currentPano.id === 'semi-oval-family' ? 'active' : ''
              }`}
              onClick={() => selectSceneById('welcome', 'semi-oval-family')}
            >
              <p>Family</p>
            </div>
          </div>
        </div>
      )}

      {isDiamondRoom && (
        <div className="mobi-controller--menu">
          <h3 className="mobi-controller--menu-title">Các mẫu nhà Diamond</h3>
          <div className="mobi-controller--menu-list">
            <div
              className={`mobi-controller--menu-item ${
                currentPano.id === 'diamond-twin' ? 'active' : ''
              }`}
              onClick={() => selectSceneById('welcome', 'diamond-twin')}
            >
              <p>Twin</p>
            </div>
            <div
              className={`mobi-controller--menu-item ${
                currentPano.id === 'diamond-king' ? 'active' : ''
              }`}
              onClick={() => selectSceneById('welcome', 'diamond-king')}
            >
              <p>King</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MobiController;
