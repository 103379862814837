import { ReactComponent as MenuBarIcon } from 'assets/icons/fa-bar.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/fa-xmark.svg';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import INFO_ICON from './info-icon.png';
import { formatText } from 'utils/limitLetters';

import './mobiMenu.scss';

const MobiMenu = ({ tourLogo, currentPano, selectSceneById }) => {
  const json = useSelector((state) => state.json);
  const [infoTagShow, setInfoTagShow] = useState(true);

  const [toggleMenu, setToggleMenu] = useState(false);

  const getParseSceneId = (sceneId) => {
    const foundScene = json.scenes.find((sce) => sce._id === sceneId);
    return foundScene.id;
  };

  const scenesList = json.groups[0].scenes;

  const handleToggleMenu = () => {
    setToggleMenu((value) => !value);
  };

  const handleSelectScene = (groupId, sceneId) => {
    selectSceneById(groupId, sceneId);
    handleToggleMenu();
  };

  const firstInfotag = useMemo(
    () =>
      currentPano?.hotspots?.find(
        (hs) => hs?.type === 'Media' && hs?.media?.infoTag
      ) || null,

    [currentPano]
  );

  useEffect(() => {
    setInfoTagShow(true);
  }, [currentPano]);

  return (
    <div className="mobi-menu-container">
      {firstInfotag && (
        <div
          className="mbh-infotag--mobile-btn"
          onClick={() => setInfoTagShow(!infoTagShow)}
        >
          {infoTagShow && <div className="mbh-infotag--mobile-arrow"></div>}

          {!infoTagShow ? (
            <img
              className="mbh-infotag--mobile-img"
              src={INFO_ICON}
              alt="mbh-cancle"
            />
          ) : (
            <CloseIcon className="close-icon icon" />
          )}
        </div>
      )}
      {firstInfotag && (
        <div className={`mbh-infotag ${!infoTagShow ? 'hide' : ''}`}>
          {/* <button
            className="mbh-infotag--btn"
            onClick={() => setInfoTagShow(false)}
          >
            x
          </button> */}

          <h2 className="mbh-infotag--title">{firstInfotag.media.title}</h2>
          <p className="mbh-infotag--text">
            {formatText(firstInfotag.media.infoTag.text)}
          </p>
        </div>
      )}

      <div
        className={`bg-close${toggleMenu ? ' open' : ''}`}
        onClick={handleToggleMenu}
      >
        {/* click outside to close */}
      </div>

      <div className={`mobi-logo${toggleMenu ? ' open' : ''}`}>
        <img src={tourLogo} alt="Logo" />
      </div>
      <div className={`menu-btn${toggleMenu ? ' open' : ''}`}>
        <div className="menu-btn-icon" onClick={handleToggleMenu}>
          <MenuBarIcon className="menu-icon icon" />
          <CloseIcon className="close-icon icon" />
        </div>
        {currentPano?.title && (
          <div className="scene-name">{currentPano?.title}</div>
        )}
      </div>

      <div className={`menu-list-container${toggleMenu ? ' open' : ''}`}>
        <div className="menu-list-logo">
          <img src={tourLogo} alt="Logo" />
        </div>
        <div className="menu-list">
          {scenesList.map((scene, index) => (
            <div
              className={`menu-list-item ${
                currentPano?._id === scene.id ? 'active' : ''
              }`}
              key={index}
              onClick={() =>
                handleSelectScene('welcome', getParseSceneId(scene.id))
              }
            >
              <img
                src={scene.previewImgUrl}
                alt={scene.title}
                className="menu-list-item-img"
              />
              <div className="menu-list-item-title">{scene.title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobiMenu;
