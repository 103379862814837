import React, { useState, useEffect, useMemo } from 'react';

import { menuHighlightDefaultColor } from 'consts';
import { HOTSPOT_UI, LAYOUT_UI } from 'consts/ui.const';
import defaultPreview from 'assets/images/default-preview.svg';
import IconFlickedArrow from './iconFlickedArrow';
import IconAirBalloon from './iconAirBalloon';

import SCENE_TYPE from '../../scene.types';
import SCENE_TYPES from '../../../consts/sceneType.const';
import IconAerialMap from './iconAerialMap';
import IconAerialScene from './iconAerialScene';
import IconGroundScene from './iconGroundScene';
import Pulse from './pulse';
import {
  getFriendlyDistance,
  feetBetween,
} from '../../../utils/distanceHelper';
import configs from '../../../configs';

import './scene.scss';
import { IconCustom } from './iconCustom';
import AquaHotspotFrame from './aqua-hotspot-frame';
import HouseTypeTitle from './HouseTypeTitle';

const SceneHotspot = (props) => {
  const { currentSceneCoords, isActiveIcon } = props;
  const { title, sceneType, previewImgUrl, coordinates } = props.data;

  const [distance, setDistance] = useState('');

  const hightLightColor = useMemo(
    () =>
      props.scope.enableBranding.value
        ? props.tour.menuHighlightColor
        : menuHighlightDefaultColor,
    [props.tour, props.scope]
  );

  const isHotspotUiDefault = useMemo(
    () => props.params && props.params['hotspot-ui'] === HOTSPOT_UI.DEFAULT,
    [props.params]
  );

  const isAirBalloonStyle = useMemo(
    () => props.hotspotStyle === HOTSPOT_UI.AIR_BALLOON,
    [props.hotspotStyle]
  );

  const isAquaStyle = useMemo(
    () => props.tour.menuStyle === LAYOUT_UI.AACORPORATION,
    [props.tour.menuStyle]
  );

  useEffect(() => {
    if (sceneType !== SCENE_TYPES.GROUND && currentSceneCoords) {
      const feet = feetBetween(currentSceneCoords, coordinates);
      if (feet) {
        const d = getFriendlyDistance(feet);
        setDistance(d);
      } else {
        setDistance('');
      }
    } else {
      setDistance('');
    }
  }, [currentSceneCoords, coordinates, sceneType]);

  let Icon = IconGroundScene;
  if (isAirBalloonStyle) {
    Icon = IconAirBalloon;
  } else if (
    (props.hotspotStyle === HOTSPOT_UI.DEFAULT || isHotspotUiDefault) &&
    !isAquaStyle
  ) {
    Icon = IconFlickedArrow;
  } else {
    switch (sceneType) {
      case SCENE_TYPE.AERIAL_MAP:
        Icon = IconAerialMap;
        break;
      case SCENE_TYPE.AERIAL:
      case SCENE_TYPES.AERIAL:
        Icon = IconAerialScene;
        break;
      default:
        Icon = IconGroundScene;
        break;
    }
  }

  const onClickHotspotParent = (e) => {
    window.logMessage('onClickHotspotParent', e);
    props.toggleHover(true);
  };

  const closeMapNavigation = () => {
    const pathname = props.history.location.pathname;
    if (pathname.includes(configs.mapRoute)) {
      const newPath = pathname.replace('/map-navigation', '');
      props.history.push(newPath);
    }
  };

  const onClickHotspot = (e) => {
    e.stopPropagation();
    window.logMessage('onClickHotspot', e);
    if (!props.isHover) {
      props.toggleHover(true);
    }
    setTimeout(props.onClick, 0);
    if (props.history) closeMapNavigation();
  };

  // const semiOvalTitle = useMemo(() => {}, [current]);

  if (isAquaStyle && props?.hotspot?.configs)
    return (
      <AquaHotspotFrame
        configs={props.hotspot.configs}
        onClickHotspot={props.onClick}
      />
    );

  return (
    <div
      className={`scene-hotspot ${
        isAirBalloonStyle || props.isHover ? 'hovering' : ''
      } ${isAirBalloonStyle ? 'scene-hotspot-air__balloon' : ''}
      ${
        null ? 'hovering scene-hotspot-air__balloon scene-hotspot-aacorp' : ''
      }`}
      onClick={onClickHotspotParent}
      onMouseEnter={props.hoverIn}
      onMouseLeave={props.hoverOut}
    >
      <div
        className={`s-animation ${props.isMapFloorPlan && 'position-unset'}`}
      >
        <div
          className={`s-animation-circle ${props.isHover ? '' : 'animating'}`}
        >
          &nbsp;
        </div>
      </div>
      <div
        className={`s-info ${
          isAirBalloonStyle || isAquaStyle ? 's-info-air_balloon-info' : ''
        }`}
      >
        <div
          className={`s-info-title ${
            isAirBalloonStyle ? 'air-balloon-title' : ''
          } 
        ${
          isAquaStyle
            ? 'air-balloon-title s-info-title_aacorp s-info-title_longtitle'
            : ''
        }
        `}
        >
          <HouseTypeTitle
            currentPano={props.currentPano}
            title={title}
            tour={props.tour}
            hotspotData={props.data}
          />
        </div>
        {distance && (
          <div className="s-info-distance">
            <p
              style={{
                color:
                  props.tour?.calloutSubHeaderColor || 'rgb(109, 113, 115)',
              }}
            >
              {distance || 'N/A'}
            </p>
          </div>
        )}
      </div>
      <div className="s-icon" onClickCapture={onClickHotspot}>
        {isAirBalloonStyle ? (
          <>
            {!isHotspotUiDefault && <Pulse />}
            {props.hotspot?.iconUrl ? (
              <IconCustom iconUrl={props.hotspot.iconUrl} />
            ) : (
              <Icon visited={props.visited} hightLightColor={hightLightColor} />
            )}
          </>
        ) : props.isMapFloorPlan && !isActiveIcon ? (
          <span className="badge-scene-text position-absolute">
            {props.index}
          </span>
        ) : (
          !props.isHover && (
            <>
              {!isHotspotUiDefault && <Pulse />}
              {props.hotspot?.iconUrl ? (
                <IconCustom iconUrl={props.hotspot.iconUrl} />
              ) : (
                <Icon
                  visited={props.visited}
                  hightLightColor={hightLightColor}
                />
              )}
            </>
          )
        )}
      </div>
      <img
        src={previewImgUrl ? previewImgUrl : defaultPreview}
        alt={title}
        draggable={false}
        onClickCapture={onClickHotspot}
      />
    </div>
  );
};

export default SceneHotspot;
