export const limitLetters = (letters, limit = 50) => {
  return letters.length > limit ? letters.slice(0, limit) + '...' : letters;
};

export const formatText = (text) => {
  return text.split('\n').map((item, index) => (
    <span key={index}>
      {item}
      <br />
    </span>
  ));
};
