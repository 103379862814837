import React, { useMemo } from 'react';

export default function HouseTypeTitle({
  currentPano,
  title,
  tour,
  hotspotData,
}) {
  const isFacecadeSemiOval = useMemo(
    () =>
      currentPano?._id === '66977d99a82dfc1b80de172e' &&
      hotspotData.id === 'mau-nha-semi-oval-twin',
    [currentPano, hotspotData.id]
  );

  const isFacecadeDimondKing = useMemo(
    () =>
      currentPano?._id === '66977da84bd0d8195896902f' &&
      hotspotData.id === 'diamond-king',
    [currentPano, hotspotData.id]
  );

  const isFamilyRestroom = useMemo(
    () =>
      currentPano?._id === '66977def4bd0d81958969075' &&
      window.currentPano.id === 'semi-oval-family',
    [currentPano]
  );

  if (isFacecadeSemiOval) {
    return (
      <p
        style={{
          color: tour?.calloutHeaderColor || 'rgb(191, 94, 93)',
        }}
        className="hpTwin"
      >
        MẪU NHÀ SEMI OVAL
        <br />
        HẠNG PHÒNG: TWIN
      </p>
    );
  }

  if (isFacecadeDimondKing) {
    return (
      <p
        style={{
          color: tour?.calloutHeaderColor || 'rgb(191, 94, 93)',
        }}
        className="hpTwin"
      >
        MẪU NHÀ DIAMOND M
        <br />
        HẠNG PHÒNG: KING
      </p>
    );
  }

  if (isFamilyRestroom) {
    return (
      <p
        style={{
          color: tour?.calloutHeaderColor || 'rgb(191, 94, 93)',
        }}
      >
        SEMI OVAL FAMILY
      </p>
    );
  }

  return (
    <p
      style={{
        color: tour?.calloutHeaderColor || 'rgb(191, 94, 93)',
      }}
    >
      {title}
    </p>
  );
}
