import React, {
  useCallback,
  useState,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Cube360 from './cube360';
import Sphere360 from './sphere360';

import { updatePrevRotation } from 'store/actions';
import { calcCamRotationY } from 'containers/world/utils';
import { trackTimeOnScene } from 'gaTracking';
import { TRANSITION_METHOD } from './transition.const';

const CUBE_TOGGLE = {
  A: 'SCENE_A',
  B: 'SCENE_B',
};

window.isIntroAnimation = true;
window.transitionMethod = '';

const SceneController = (props) => {
  const { tour } = useSelector((state) => state);
  const { pano, scenes, onSwitchScene } = props;
  const lastScene = useRef(pano);
  const [sceneAData, setSceneAData] = useState({
    scene: pano,
    stepPosition: [0, 0, 0],
    sceneRotation: [0, 0, 0],
  });
  const [sceneBData, setSceneBData] = useState({
    scene: null,
    stepPosition: [0, 0, 0],
    sceneRotation: [0, 0, 0],
  });

  const [currentCube, setCurrentCube] = useState(CUBE_TOGGLE.A);
  const dispatch = useDispatch();
  const prevRotation = useRef(0);
  const rotations = useRef([{ rad: 0, deg: 0 }]);
  const stepClickingData = useRef(null);

  const handleClickStep = useCallback(
    (sceneID, stepPosition, sceneRotation) => {
      const targetScene = scenes.find((scene) => scene._id === sceneID);
      if (targetScene) {
        stepClickingData.current = {
          scene: targetScene,
          stepPosition,
          sceneRotation,
        };
        onSwitchScene(targetScene.groupId, targetScene.id);
      }
    },
    [onSwitchScene, scenes]
  );

  useEffect(() => {
    // check if the next scene is a step => do ground nav too
    if (!stepClickingData.current && lastScene.current._id !== pano._id) {
      const step = (lastScene.current.steps || []).find(
        (s) => s.targetSceneId === pano._id
      );
      if (step) {
        const rotationY = calcCamRotationY(
          step.cameraPosition,
          step.targetSceneCameraPosition
        );
        stepClickingData.current = {
          scene: pano,
          stepPosition: step.stepPosition,
          sceneRotation: [0, rotationY, 0],
        };
      }
    }

    if (
      stepClickingData.current &&
      pano._id === stepClickingData.current.scene._id
    ) {
      window.transitionMethod = TRANSITION_METHOD.GROUND_NAV;
      const { scene, stepPosition, sceneRotation } = stepClickingData.current;
      prevRotation.current += sceneRotation[1];

      // Save prev rotation in store
      dispatch(updatePrevRotation(prevRotation.current));

      rotations.current.push({
        rad: prevRotation.current,
        deg: (prevRotation.current * 180) / Math.PI,
      });
      const targetSceneData = {
        scene,
        stepPosition: [...stepPosition],
        sceneRotation: [...sceneRotation],
      };
      if (currentCube === CUBE_TOGGLE.A) {
        setSceneBData(targetSceneData);
        setCurrentCube(CUBE_TOGGLE.B);
      } else {
        setSceneAData(targetSceneData);
        setCurrentCube(CUBE_TOGGLE.A);
      }
    }

    // handle change scene which is not step
    if (!stepClickingData.current && pano.panoType !== 'Pano') {
      window.transitionMethod = TRANSITION_METHOD.JUMP;
      prevRotation.current = 0;
      dispatch(updatePrevRotation(0));
      rotations.current.push({
        rad: 0,
        deg: 0,
      });
      const setSceneCallback = (d) => ({
        ...d,
        scene: pano,
      });
      if (currentCube === CUBE_TOGGLE.A) {
        setSceneAData(setSceneCallback);
      } else {
        setSceneBData(setSceneCallback);
      }
    }

    stepClickingData.current = null;

    lastScene.current = pano; // store current pano for reference next time pano change

    // eslint-disable-next-line
  }, [lastScene, scenes, pano]);

  const [cubeA, cubeB] = useMemo(() => {
    const commonCubeHandlers = {
      prevRotation: prevRotation.current,
      rotations: rotations.current,
      onClickStep: handleClickStep,
    };

    const firstCubeHandlers = {
      ...commonCubeHandlers,
      sceneData: sceneAData.scene,
      stepPosition: sceneAData.stepPosition,
      sceneRotation: sceneAData.sceneRotation,
      positionNext: sceneBData.stepPosition,
      active: currentCube === CUBE_TOGGLE.A,
    };

    const secondCubeHandlers = {
      ...commonCubeHandlers,
      sceneData: sceneBData.scene,
      stepPosition: sceneBData.stepPosition,
      sceneRotation: sceneBData.sceneRotation,
      positionNext: sceneAData.stepPosition,
      active: currentCube === CUBE_TOGGLE.B,
    };

    return [firstCubeHandlers, secondCubeHandlers];
  }, [
    prevRotation,
    rotations,
    handleClickStep,
    sceneAData,
    sceneBData,
    currentCube,
  ]);

  useEffect(() => {
    if (props?.pano.id !== 'restroom-semi-oval') {
      window.currentPano = props?.pano;
    }
    const intervalId = trackTimeOnScene({
      tour_id: tour.id,
      scene_id: props?.pano?._id,
    });
    return () => {
      clearInterval(intervalId);
    };

    // eslint-disable-next-line
  }, [props?.pano?.id]);

  return (
    <>
      <Cube360 {...props} pano={cubeA.sceneData} stepHandlers={cubeA} />
      <Cube360 {...props} pano={cubeB.sceneData} stepHandlers={cubeB} />
      <Sphere360 {...props} sceneData={pano} />
      {/* X: red. Y: green. Z: blue. */}
      {/* {configs.local && <axesHelper arg={[100]} />} */}
    </>
  );
};

export default SceneController;
