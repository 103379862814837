export const HOTSPOT_UI = {
  DEFAULT: 'default',
  CALLOUT: 'callout',
  AIR_BALLOON: 'air-balloon',
};

export const LAYOUT_UI = {
  GALLERY: 'gallery',
  SCHOOL: 'school',
  AACORPORATION: 'aqua',
  AVORIAZ: "avoriaz",
  MOBIHOME: "mobihome",
  CLEAR_SPACE: "clear-space",
  RESORT: "resort",
};

export const SOCIAL_ICON_TYPE = {
  EMAIL: "EMAIL",
  WEBSITE: "WEBSITE",
  YOUTUBE: "YOUTUBE",
  FACEBOOK: "FACEBOOK",
  INSTAGRAM: "INSTAGRAM",
  TWITTER: "TWITTER",
}
